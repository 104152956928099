.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox,
.form-radio {
  &:focus {
    @apply ring-0;
  }
}
